import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'

import thumb01 from '../assets/images/thumbs/01.jpg'
import thumb02 from '../assets/images/thumbs/02.jpg'
import thumb03 from '../assets/images/thumbs/03.jpg'
import thumb04 from '../assets/images/thumbs/04.jpg'

import full01 from '../assets/images/fulls/01.jpg'
import full02 from '../assets/images/fulls/02.jpg'
import full03 from '../assets/images/fulls/03.jpg'
import full04 from '../assets/images/fulls/04.jpg'

const DEFAULT_IMAGES = [
    { id: '1', src: full01, thumbnail: thumb01, caption: '', description: ''},
    { id: '2', src: full02, thumbnail: thumb02, caption: '', description: ''},
    { id: '3', src: full03, thumbnail: thumb03, caption: '', description: ''},
    { id: '4', src: full04, thumbnail: thumb04, caption: '', description: ''}
];

class HomeIndex extends React.Component {

    constructor() {
        super();

        this.state = {
            lightboxIsOpen: false,
            currentImage: 0,
        };

        this.closeLightbox = this.closeLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.handleClickImage = this.handleClickImage.bind(this);
    }

    openLightbox (index, event) {
        event.preventDefault();
        this.setState({
            currentImage: index,
            lightboxIsOpen: true,
        });
    }
    closeLightbox () {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }
    gotoPrevious () {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }
    gotoNext () {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }
    handleClickImage () {
        if (this.state.currentImage === this.props.images.length - 1) return;

        this.gotoNext();
    }

    render() {
        const siteTitle = "Outside In"
        const siteDescription = "Musculoskeletal Therapy, Myotherapy, and Massage - Cooroy, Noosa Hinterland"

        return (
            <Layout>
            <div>
                <Helmet>
                        <title>{siteTitle} - {siteDescription}</title>
                        <meta name="description" content={siteDescription} />
                </Helmet>

                <div id="main">

                    <div>
                        <ul className="actions">
                            <li><a href="#one" className="button">Our Practitioners</a></li>
                            <li><a href="#two" className="button">Treatments</a></li>
                            <li><a href="#three" className="button">Price List</a></li>
                            <li><a href="#four" className="button">Contact Us</a></li>
                        </ul>
                    </div>

                    <section id="one">
                        <header className="major">
                            <h2>Kathryn Cross <br />
                            BHSc Musculoskeletal / Clinical Myotherapist</h2>
                        </header>
                        <p>Kathryn’s clinical background includes working as a Registered Nurse within general medical, surgical and Oncological /Haematological care settings. Her qualifications include: Batchelor of Health Science with a major in Musculoskeletal Therapy/Myotherapy, Diploma of Remedial Massage, and Certificate IV in Workplace Training and Assessment.<br/><br/> Kathryn has a special interest in chronic pain management, autoimmune/inflammatory disease, and correcting biomechanical dysfunction. She acknowledges that chronic pain may often be the result of past trauma and adaptive functional movement impairment patterns. Therefore, her assessment and treatment have a focus on functional impairments of nerve, muscle, fascia and joints. Treatment may include neural mobilisation, electrotherapeutic dry needling, spinal and peripheral joint mobilisation, facilitated stretching, corrective exercise prescription, transcutaneous auricular vagus nerve stimulation and nutritional advice.<br/><br/> Kathryn is a member of the Australian Traditional Medicine Society (ATMS) and the Myotherapy Association of Australia (MAA).</p>
                    </section>

                    <section id="two">
                        <header className="major">
                            <h2>Treatments</h2>
                        </header>

                        <h3>Musculoskeletal Therapy</h3>
                        <p>
                        Musculoskeletal therapy (also known as Clinical Myotherapy) is a branch of functional medicine with a focus on neuro-musculoskeletal assessment and treatment. Musculoskeletal clinicians offer advanced assessment, including orthopaedic &amp; neurological testing. Treatment may include soft tissue / joint mobilisation, neuro-mobilisation, myofascial techniques, dry needling, electro-dry needling, facilitated stretching, exercise prescription &amp; nutritional advice.
                        </p>
  
                        <h3>Remedial Massage</h3>
                        <p>
                        Remedial massage is the systematic assessment and treatment of the muscles, tendons, ligaments and connective tissues of the body to assist in rehabilitation, pain and injury management. A remedial therapist understands that a patient’s needs are unique, and therefore particular remedial massage techniques need to be selected with clinical expertise and applied individually to suit each patient.</p>

                        <Gallery images={DEFAULT_IMAGES.map(({ id, src, thumbnail, caption, description }) => ({
                            src,
                            thumbnail,
                            caption,
                            description
                        }))} />

                    </section>

                    <section id="three">
                        <header className="major">
                            <h2>Price List</h2>
                        </header>
                        <p>
                        <h3>Musculoskeletal Therapy / Clinical Myotherapy</h3>
                        <ul>
                            <li>Initial assessment &amp; treatment $135</li>
                            <li>Follow up consultation $95</li>
                        </ul>
                        <h3>Bookings &amp; Health Fund Rebates</h3>
                        <ul>
                            <li>Treatment by appointment only.</li>
                            <li>HICAPS facilities and private health fund rebates are available for musculoskeletal therapy/myotherapy and remedial massage.</li>
                            <li>Who can claim a rebate? Health fund members holding the appropriate cover for remedial massage, myotherapy or musculoskeletal therapy may claim a rebate. To qualify for a rebate, members must present with a musculoskeletal condition or injury, or require assistance with musculoskeletal rehabilitation, disease management or preventive medicine.</li>
                            <li>Cancellations are sometimes unavoidable, but we would appreciate 24 hours’ notice. A cancellation fee may apply in some cases.</li>                            
                        </ul>
                           
                        </p>
                    </section>

                    <section id="four">
                        <h2>Contact Us</h2>
                        <p></p>
                        <div className="row">
                            <div>
                                <ul className="labeled-icons">
                                    <li>
                                        <h3 className="icon fa-home"><span className="label">Address</span></h3>
                                        20 Olivine St,<br />
                                        Cooroy QLD 4563<br />
                                        Australia
                                    </li>
                                    <li>
                                        <h3 className="icon fa-phone"><span className="label">Phone</span></h3>
                                        <a href="tel:07 5447 8004">(07) 5447 8004</a>
                                    </li>
                                    <li>
                                        <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
                                        <a href="mailto:outsidein@outsidein.biz">outsidein@outsidein.biz</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>

                    <section id="five">
                        <div>Original Design: <a href="http://html5up.net">HTML5 UP</a></div>
                    </section>

                </div>

            </div>
            </Layout>
        )
    }
}

export default HomeIndex